import * as React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";

import kukusend from "../images/kukusend.png";
import mallamx from "../images/mallamxview.png";

import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Wrapper = styled.div``;

const PageLayout = styled.div`
  width: 65%;
  margin: 0 auto;
  margin-top: 7rem;
  padding: 1rem;

  @media screen and (max-width: 1100px) {
    width: 75%;
  }

  @media screen and (max-width: 600px) {
    width: 85%;
  }

  @media screen and (max-width: 360px) {
    width: 93%;
  }
`;

const Heading = styled.div`
  margin-bottom: 3rem;
  text-align: center;
`;

const HeadingMain = styled.h3`
  font-size: 2.2rem;
  text-decoration: underline;
`;

const HeadingSecondary = styled.p`
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: 300;
  line-height: 25px;
  text-align: center;
`;

const Main = styled.main`
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 44rem;
  grid-row-gap: 7rem;
  grid-column-gap: 3rem;
  width: 100%;

  @media screen and (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;

const Project = styled.div`
  border: 1px solid white;
  border-radius: 0.5rem;
  /* background: palegoldenrod; */
`;

const ProjectImageContainer = styled.div`
  height: 60%;
  padding: 0.2rem;
  transition: 0.5s all;

  &:hover {
    transform: scale(1.03);
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 100%;
  /* filter: grayscale(100%);
   */
  filter: blur(15px);
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    filter: none;
  }
`;

const ProjectDescription = styled.div`
  height: 30%;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: 300;
  font-size: 1.4rem;
`;

const ProjectName = styled.div`
  border-top: 1px solid white;
  padding: 0.5rem;
  line-height: 3rem;
  flex: 0 0 20%;
`;

const ProjectNameItem = styled.span``;

const ProjectDescriptionText = styled.div`
  flex: 1;
  border-top: 1px solid white;
  padding: 0.5rem;
  text-align: center;
`;

const ProjectTags = styled.ul`
  flex: 0 0 30%;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 0.5rem;
  display: flex;
  justify-content: space-evenly;
  flex-grow: 1;
  list-style: none;
`;

const ProjectTagsItem = styled.li``;

const ProjectLinks = styled.div`
  padding: 0.5rem;
  flex: 0 0 20%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const ProjectLinksLink = styled.a``;

const ProjectLinksLinkText = styled.span`
  display: inline-block;
  margin-right: 0.5rem;
  color: white;
`;

const OutLink = styled.a`
  color: white;
`;

const WorkPage = () => {
  return (
    <Layout>
      <Wrapper>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Tochukwu Nwanguma - Projects</title>
          <meta
            name="description"
            content="Tochukwu Nwanguma is a top-rated software engineer based in Lagos. With expertise in frontend, backend, he brings business ideas to life with cutting-edge technologies."
          />
          <meta
            name="keywords"
            content="frontend engineer, software engineer, fullstack engineer, engineers in Lagos, fintech engineer, Tochukwu Nwanguma, Lagos developer, Lagos software engineer, fintech solutions, business technology"
          />
          <link
            rel="canonical"
            href="https://www.tochukwunwanguma.com/projects"
          />
        </Helmet>
        <PageLayout>
          <Heading>
            <HeadingMain>A few of my projects</HeadingMain>
            <HeadingSecondary>
              I used to freelance on the side but now I'm focused on{" "}
              <OutLink>Immense studio</OutLink> which is a company I founded to
              build my personal projects.
            </HeadingSecondary>
          </Heading>
          <Main>
            {/* <Project>
              <ProjectImageContainer>
                <ProjectImage src={kukusend} alt="" />
              </ProjectImageContainer>
              <ProjectDescription>
                <ProjectName>
                  <ProjectNameItem>Clent: Kukusend</ProjectNameItem>
                </ProjectName>
                <ProjectDescriptionText>
                  Transfer funds, send cash, pay bills, buy giftcards and a
                  couple more things.
                </ProjectDescriptionText>
                <ProjectTags>
                  <ProjectTagsItem>React</ProjectTagsItem>
                  <ProjectTagsItem>React-Redux</ProjectTagsItem>
                  <ProjectTagsItem>Styled-Components</ProjectTagsItem>
                </ProjectTags>
                <ProjectLinks>
                  <ProjectLinksLink href="https://kukusend.com" target="_blank">
                    <ProjectLinksLinkText>Visit site</ProjectLinksLinkText>
                    <FontAwesomeIcon icon={faExternalLinkAlt} color="#fff" />
                  </ProjectLinksLink>
                </ProjectLinks>
              </ProjectDescription>
            </Project> */}
            <Project>
              <ProjectImageContainer>
                {/* <ProjectImage src={kukusend} alt="" /> */}
              </ProjectImageContainer>
              <ProjectDescription>
                <ProjectName>
                  <ProjectNameItem>Personal: LiveCycle</ProjectNameItem>
                </ProjectName>
                <ProjectDescriptionText>
                  Get user feedback in realtime.
                </ProjectDescriptionText>
                <ProjectTags>
                  <ProjectTagsItem>Microservices</ProjectTagsItem>
                  <ProjectTagsItem>NextJS</ProjectTagsItem>
                  <ProjectTagsItem>NodeJS/ExpressJS</ProjectTagsItem>
                  <ProjectTagsItem>Docker</ProjectTagsItem>
                </ProjectTags>
                <ProjectLinks>
                  <ProjectLinksLink
                    href="https://github.com/livecyclepro"
                    target="_blank"
                  >
                    <ProjectLinksLinkText>Visit Github</ProjectLinksLinkText>
                    <FontAwesomeIcon icon={faExternalLinkAlt} color="#fff" />
                  </ProjectLinksLink>
                </ProjectLinks>
              </ProjectDescription>
            </Project>
            <Project>
              <ProjectImageContainer>
                {/* <ProjectImage src={kukusend} alt="" /> */}
              </ProjectImageContainer>
              <ProjectDescription>
                <ProjectName>
                  <ProjectNameItem>Personal: Barter</ProjectNameItem>
                </ProjectName>
                <ProjectDescriptionText>
                  List and exchange goods and services
                </ProjectDescriptionText>
                <ProjectTags>
                  <ProjectTagsItem>NodeJS/ExpressJS</ProjectTagsItem>
                </ProjectTags>
                <ProjectLinks>
                  <ProjectLinksLink
                    href="https://github.com/nwanguma/barter-api"
                    target="_blank"
                  >
                    <ProjectLinksLinkText>Visit Github</ProjectLinksLinkText>
                    <FontAwesomeIcon icon={faExternalLinkAlt} color="#fff" />
                  </ProjectLinksLink>
                </ProjectLinks>
              </ProjectDescription>
            </Project>
          </Main>
        </PageLayout>
      </Wrapper>
    </Layout>
  );
};

export default WorkPage;
